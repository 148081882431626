<template>
    <div>
        <v-text-field
            outlined dense
            :hide-details="true"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
        >

        </v-text-field>
        <v-data-table
                v-if="itemData && headers"
                height="50vh"
                :search="search"
                :headers="headers"
                :items="itemData"
                no-data-text="データーがありません"
                :item-key="itemKey"
                :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text': '表示数',
                    'items-per-page-options': [10, 50, -1],
                }"
                show-select
                v-model="selected"
        >
            <template v-slot:item.status="{item}">
                {{item.inUseCount}}件
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props:['dataType'],
    data:()=>({
        search: "",
        itemData: [],
        headers: null,
        selected:[],
        itemKey:null
    }),
    async mounted(){
        this.headers = this.generateHeaders()
        let labelArr = this.dataType.split('')
        labelArr[0] = labelArr[0].toUpperCase()
        this.itemKey = `${this.dataType}Code`
        const result = await this.$get[`get${labelArr.join('')}`]()
        if(result) this.itemData = result
    },
    watch:{
        selected(){
            this.$emit('syncData', this.selected)
        }
    },
    methods:{
        generateHeaders(){
            return [
                {
                    text: "コード",
                    align: "start",
                    value: `${this.dataType}Code`,
                },
                { text: "ラベル", value: `${this.dataType}Name` },
                { text: "利用件数", value: "status" },
            ]
        }
    }

}
</script>

<style>

</style>