<template>
    <div>
        <div class="banner pa-5 text-center text-h3 rounded my-3">
            現場・工種管理
        </div>
        <div class="d-flex justify-center">
            <v-btn color="success" @click="display.newForm = true">
                <v-icon>mdi-plus</v-icon>
                新規登録
            </v-btn>
            <v-btn color="success" class="mx-3" @click="display.importForm = true">
                <v-icon>mdi-plus</v-icon>
                CSV一括登録
            </v-btn>
            <v-btn color="error" @click="deleteGenbaKoushu">削除</v-btn>
        </div>

        <v-divider class="my-3"/>
        <div class="caption mb-3">現場</div>
        <data-table 
            :key="'genbaTable'+key.genba" 
            :dataType="'genba'" 
            @syncData="selected.genba = $event"
        />
        <v-divider class="my-3"/>
        <div class="caption mb-3">工種</div>
        <data-table 
            :key="'koushuTable'+key.koushu" 
            :dataType="'koushu'"
            @syncData="selected.koushu = $event" 
        />
        
        <!--Dialogs-->
        <v-dialog v-model="display.newForm">
            <genba-koushu-form @refresh="refresh()" @close="display.newForm = false"/>
        </v-dialog>
        <v-dialog v-model="display.importForm">
            <bulk-import @refresh="refresh()" @close="display.importForm = false"/>
        </v-dialog>
        

    </div>
</template>

<script>
import DataTable from '@/components/GenbaKoushu/DataTable.vue'
import GenbaKoushuForm from '@/components/GenbaKoushu/GenbaKoushuForm.vue'
import BulkImport from '@/components/GenbaKoushu/BulkImport.vue'
export default {
    data:()=>({
        display:{
            newForm : false,
            importForm:false
        },
        key:{
            genba : 0,
            koushu : 0,
        },
        selected:{
            genba:[],
            koushu:[]
        }
    }),
    methods:{
        async deleteGenbaKoushu(){
            const response = await this.$store.dispatch('post', {url:'/api/deleteGenbaKoushu', showUI:true, rawData:this.selected})
            if(response && response.result)
                this.refresh()
        },  
        refresh(){
            this.key.genba ++
            this.key.koushu ++
        }
    },
    components:{
        GenbaKoushuForm,
        DataTable,
        BulkImport
    }
}
</script>