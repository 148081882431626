<template>
    <v-sheet>
        <div class="d-flex mt-3">
            <v-file-input
                v-model="uploadedCSV"
                outlined
                accept=".csv"
                label="CSVファイルをアップロード"
                @change="checkFile"
            ></v-file-input>
        </div>
        <div v-if="err" style="height:250px; overflow-y:scroll;" class="my-3">
            <div class="error--text">エラーが{{err.count}}件検出しました</div>
            <v-simple-table class="error--text">
                <template v-slot:default>
                    <tbody>
                        <tr
                            v-for="(errMsg, index) in err.msg"
                            :key="index"
                        >
                            <td>{{ index + 1 }}</td>
                            <td>{{ errMsg }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div  class="ma-3 d-flex justify-end">
            <v-btn color="primary" @click="downloadCSVTemplate">テンプレートダウンロード</v-btn>
            <v-btn v-if="uploadedCSV" class="ml-3" color="success" @click="addGenbaKoushu">
                登録
            </v-btn>
        </div>
    </v-sheet>
</template>

<script>
import Papa from 'papaparse'
import Encoding from 'encoding-japanese';
export default {
    data:()=>({
        uploadedCSV:null,
        parsedData:null,
        err:null
    }),
    methods:{
        checkFile(e){
            if(!this.uploadedCSV) return
            let vm = this
            const reader = new FileReader();
            reader.onload = (e) => {
                const codes = new Uint8Array(e.target.result);
                const encoding = Encoding.detect(codes);
                const unicodeString = Encoding.convert(codes, {
                    to: 'unicode',
                    from: encoding,
                    type: 'string',
                });
                Papa.parse(unicodeString,{
                    skipEmptyLines: true,
                    complete:async(results)=>{
                        vm.parsedData = {
                            headers:results.data.shift(),
                            parsedData:results.data
                        }
                    }
                })
            };
            reader.readAsArrayBuffer(e);
        },
        async addGenbaKoushu(){
            const response = await this.$store.dispatch('post', {url:'/api/addGenbaKoushu', showUI:true, rawData:this.parsedData})
            if(response && response.result)
                if(response.result.count > 0)
                    this.err = response.result
                else{
                    this.$emit('refresh')
                    this.$emit('close')
                }
        },
        async downloadCSVTemplate(){
            const templateLayout = {
                "fields":["コード","ラベル","現場/工種"],
                "data":[
                    ["ABC123","現場例8989","現場"],
                    ["CBA321","工種例234","工種"],
                    ["","",""],
                ]
            }
            const templateCSV_String = Papa.unparse(templateLayout)
            this.$gf.downloadCSV(templateCSV_String, "現場・工種登録テンプレート")
        },
    }
}
</script>