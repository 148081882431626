<template>
    <v-sheet>
        <v-form v-model="formValid" class="pa-3">
            <v-text-field
                v-model="formData.genbaName"
                outlined dense
                :rules="[(v) => !!v || '必要項目です']"
                label="ラベル"
            />
            <v-text-field
                v-model="formData.genbaCode"
                outlined dense
                :rules="[
                    (v) => !!v || '必要項目です',
                    (v) => (v && v.length <= 100) || '最大100文字',
                ]"
                :readonly="autoGen ? true : false"
                label="コード"
            />
            <v-select
                v-model="formData.type"
                outlined dense
                label="種類"
                :items="['現場','工種']"
            >
            </v-select>
            <v-checkbox
                :label="'コード自動生成'"
                v-model="autoGen"
            />
            <div class="d-flex justify-end">
                <v-btn :disabled="!formValid" @click="addGenbaKoushu" color="success">追加</v-btn>
            </div>
        </v-form>
    </v-sheet>
</template>

<script>
import cerceisLib from 'cerceislib'
export default {
    data:()=>({
        formValid: false,
        formData:{
            type:"現場",
            genbaCode:null,
            genbaName:null
        },
        autoGen:false
    }),
    methods:{
        async addGenbaKoushu(){
            if(!this.formValid) return
            const response = await this.$store.dispatch('post', {url:'/api/addGenbaKoushu', showUI:true, rawData:{
                headers:["コード","ラベル","現場/工種"],
                parsedData:[
                    [this.formData.genbaCode, this.formData.genbaName , this.formData.type]
                ]
            }})
            if(response && response.result){
                this.formData = {
                    type:"現場",
                    genbaCode:null,
                    genbaName:null
                }
                this.autoGen = false
                this.$emit('refresh')
                this.$emit('close')
            }
                
        },
    },
    watch: {
		autoGen(){
            if (this.autoGen)
                this.formData.genbaCode = cerceisLib.generateRandom(
                    "Number+String",
                    6
                )
		},
	},
}
</script>

<style>

</style>